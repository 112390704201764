import styled from '@emotion/styled';
import { DocumentType, MemoType } from '@innedit/innedit-type';
import { MemoData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import { ListItemProps } from '~/containers/Espace/List/props';
import getMediaImageData from '~/utils/getMediaImageData';

import ListCard from '../../../containers/Espace/Card';
import IconEye from '../../../icons/Eye';
import ImagePhoto from '../../../images/Photo';
import colors from '../../../styles/colors';
import Button from '../../Button';

const BodyCard = styled.div`
  em {
    color: ${colors.secondary[500]};
    font-weight: 600;
  }
`;

const ListCardMemo: FC<ListItemProps<MemoType, MemoData>> = ({
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] = useState<DocumentType<MemoType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return (
      <li className="flex flex-col rounded !p-0">
        <div className="relative flex flex-auto flex-col-reverse bg-white-50 pb-full">
          <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center">
            <ImagePhoto color={colors.light[600]} size={100} />
          </div>
        </div>

        <div className="flex flex-row justify-around rounded-b border-t border-gray-300 bg-white-50 p-0.5">
          <Button iconLeft={IconEye} variant="card" />
        </div>
      </li>
    );
  }

  let media;

  const { medias } = doc;

  if (medias && medias.length > 0) {
    [media] = medias;
  }

  const image =
    media &&
    getMediaImageData({
      media,
      breakpoints: [150, 240, 320, 480, 640],
      maxWidth: 640,
      transformations: ['c_contain', 'b_ffffff', 'r_1'],
    });

  // const handleHiddenOnClick = (
  //   event: SyntheticEvent<HTMLButtonElement>,
  // ): void => {
  //   event.preventDefault();
  //
  //   if (doc) {
  //     model.update(doc.id, {
  //       ...doc,
  //       hidden: !doc.hidden,
  //     });
  //   }
  // };

  const handleOnClick = (event: SyntheticEvent<HTMLElement>) => {
    onClick(event);
  };

  return (
    <ListCard
      displayEdit={false}
      displayId={false}
      doc={doc}
      index={index}
      onClick={handleOnClick}
    >
      <figure
        className="relative w-full cursor-pointer rounded-t border-b pb-full"
        data-id={doc.id}
        onClick={handleOnClick}
        role="presentation"
      >
        <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center rounded">
          <ImagePhoto color={colors.light[600]} size={100} />
        </div>
        {image && (
          <img {...image.images} alt={doc.label} className="absolute rounded" />
        )}
      </figure>
      <BodyCard className="text-sm px-2  py-0.5">
        <h2 className="truncate text-center">{doc.label}</h2>
      </BodyCard>
    </ListCard>
  );
};

export default ListCardMemo;
