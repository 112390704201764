import { DocumentType, FeatureType, MemoType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import { compact } from 'lodash';
import { FeatureData, MemoData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Card from '~/components/List/Card/Memo';
import Item from '~/components/List/Item/Memo';
import List from '~/containers/Espace/List';
import params from '~/params/memo.json';
import { ReducerCMSProps } from '~/reducers/cms';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const MemosPage: FC<
  PageProps & EspaceProps & UserProps & { pageContext: { tab?: number } }
> = ({
  espace,
  location,
  user,
  pageContext: { tab },
  params: { espaceId },
}) => {
  const [features, setFeatures] = useState<DocumentType<FeatureType>[]>();
  const { t } = useTranslation();

  const channels = useSelector(
    (state: { cms: ReducerCMSProps }) => state.cms.channels,
  );

  useEffect(() => {
    const feature = new FeatureData({
      espace,
      espaceId: espace.id,
    });

    const unsub = feature.watch(
      docs => {
        if (setFeatures) {
          setFeatures(docs);
        }
      },
      {
        orderDirection: 'asc',
        orderField: 'label',
        wheres: {
          hidden: false,
        },
      },
    );

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [espace.id]);

  const initializeData: Partial<MemoType> = {
    channels:
      channels && channels.length > 0
        ? channels.reduce((acc, value) => {
            const newAcc = { ...acc };
            newAcc[value.id] = {
              isVisible: true,
            };

            return newAcc;
          }, {} as { [key: string]: { isVisible: boolean } })
        : {},
  };

  return (
    <TemplateEspace espace={espace} user={user}>
      <List<MemoType, MemoData>
        filters={compact([
          features &&
            features.length > 0 && {
              label: 'Caractéristique',
              multiple: true,
              name: 'search.features',
              options: features.map(feature => ({
                label: feature.label,
                value: feature.id,
              })),
              type: 'array',
            },
        ])}
        initializeData={initializeData}
        itemGrid={Card}
        itemList={Item}
        model={new MemoData({ espaceId, params })}
        search={location.search}
        tabIndex={tab ?? 0}
        title={t('memos.title')}
        user={user}
      />
    </TemplateEspace>
  );
};

export default requireEspace(MemosPage);
