import { DocumentType, MemoType } from '@innedit/innedit-type';
import { MemoData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import Media from '~/components/Media';
import { ListItemProps } from '~/containers/Espace/List/props';

import ListItem from './index';

const ListItemMemo: FC<ListItemProps<MemoType, MemoData>> = ({
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] = useState<DocumentType<MemoType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  const description =
    doc.description && doc.description.length > 250
      ? `${doc.description.slice(0, 250)}...`
      : doc.description;

  let media;

  if (doc.medias && doc.medias.length > 0) {
    [media] = doc.medias.filter(m =>
      ['video/', 'image/'].includes(m.type?.slice(0, 6)),
    );
  }

  return (
    <ListItem
      // actions={[
      //   {
      //     icon: doc.hidden ? IconEyeSlash : IconEye,
      //     label: 'Changer la visibilité',
      //   },
      // ]}
      date={doc.publishedAt}
      description={description}
      displayActionId={false}
      doc={doc}
      icon={
        media && (
          <Media
            media={media}
            options={{
              crop: 'contain',
              height: 42,
              width: 42,
            }}
            thumbnail
          />
        )
      }
      index={index}
      label={doc.label}
      onClick={onClick}
    />
  );
};

export default ListItemMemo;
